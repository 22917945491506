import React from 'react';

import { Animator, Dots, GridLines, MovingLines, Text } from "@arwes/react";
import { FrameCorners } from '@arwes/react-frames';

import "./HomePage.css";

import { BsCurrencyBitcoin, BsDownload, BsHouseDoor, BsPerson } from "react-icons/bs";

interface HomePageProps { }
interface HomePageState {
    overIndex: number;
 }

class HomePage extends React.Component<HomePageProps, HomePageState> {

    constructor(props: HomePageProps) {
        super(props);
        this.state = {
            overIndex: -1
        }
    }


    render() {
        const { overIndex } = this.state;
        return (
            <div className="HomePage ">
                <Animator active={true} duration={{ enter: 1, exit: 1 }}>
                    <div style={{
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        backgroundColor: '#000906',
                        backgroundImage: 'radial-gradient(85% 85% at 50% 50%, hsla(185, 100%, 25%, 0.25) 0%, hsla(185, 100%, 25%, 0.12) 50%, hsla(185, 100%, 25%, 0) 100%)'
                    }}>
                        {/* Canvas element will ocupy the positioned parent element. */}
                        <GridLines
                            lineColor='hsla(180, 100%, 75%, 0.05)'
                            distance={30}
                        />
                        <Dots
                            color='hsla(180, 100%, 75%, 0.08)'
                            distance={30}
                        />
                        <MovingLines
                            // lineColor='hsla(180, 100%, 75%, 0.07)'
                            lineColor='hsla(210, 100%, 75%, 0.2)'
                            distance={30}
                            sets={20}
                        />
                        <div className='center'>
                            <Animator active={true} duration={{ enter: 1, exit: 1 }}>
                                <Text
                                    style={{ color: '#2beded', textShadow: '0 0 5px #27e1fa', letterSpacing: '1rem' }}
                                    className='letter-spacing'
                                    manager='decipher'
                                    easing='outSine'
                                    fixed
                                >
                                    XROCKET
                                </Text>
                                <Text
                                    style={{
                                        color: '#2beded',
                                        fontSize: '1rem',
                                        // textShadow: '0 0 5px #27e1fa',
                                        opacity: 0.8,
                                        letterSpacing: '0.4rem',
                                        wordBreak: 'keep-all'
                                    }}

                                    manager='decipher'
                                    easing='outSine'
                                    fixed
                                >
                                    Secure, Private, and Reliable
                                </Text>
                            </Animator>
                            {/* #ffff80 */}
                            <div style={{
                                height: '2rem',
                            }}></div>
                            <div className="homeMenu flex gap-2x justify-evenly margin-top-lg">
                                <div className='cursor menu-item' onMouseOver={() => {
                                    this.setState({ overIndex: 0 });
                                }} onMouseLeave={() => {
                                    this.setState({ overIndex: -1 });
                                }}>
                                    <FrameCorners
                                        cornerLength={4}
                                        strokeWidth={overIndex === 0 ? 2 : 1}
                                    />
                                    <div className='text-yellow  text-normal align-center gap-sm flex justify-center'>
                                        <BsHouseDoor size={16} />
                                        <div>Home</div>
                                    </div>
                                </div>
                                <div className='cursor menu-item' onMouseOver={() => {
                                    this.setState({ overIndex: 1 });
                                }} onMouseLeave={() => {
                                    this.setState({ overIndex: -1 });
                                }}>
                                    <FrameCorners
                                        cornerLength={4}
                                        strokeWidth={overIndex === 1 ? 2 : 1}
                                    />
                                    <div className='text-yellow  text-normal align-center gap-sm flex justify-center'>
                                        <BsDownload size={16} />
                                        <div>Download</div>
                                    </div>
                                </div>
                                <div className='cursor menu-item' onMouseOver={() => {
                                    this.setState({ overIndex: 2 });
                                }} onMouseLeave={() => {
                                    this.setState({ overIndex: -1 });
                                }}>
                                    <FrameCorners
                                        cornerLength={4}
                                        strokeWidth={overIndex === 2 ? 2 : 1}
                                    />
                                    <div className='text-yellow  text-normal align-center gap-sm flex justify-center'>
                                        <BsCurrencyBitcoin size={16} />
                                        <div>Pricing</div>
                                    </div>
                                </div>
                                <div className='cursor menu-item' onMouseOver={() => {
                                    this.setState({ overIndex: 3 });
                                }} onMouseLeave={() => {
                                    this.setState({ overIndex: -1 });
                                }}>
                                    <FrameCorners
                                        cornerLength={4}
                                        strokeWidth={overIndex === 3 ? 2 : 1}
                                    />
                                    <div className='text-yellow text-normal align-center gap-sm flex justify-center'>
                                        <BsPerson size={16} />
                                        <div>Sign in</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Animator>
            </div>
        );
    }
}

export default HomePage;